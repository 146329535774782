/* global document, window */
const debounce = require('lodash/debounce');
const trim = require('lodash/trim');
const debug = require('debug')('webstore:inputSuggestions');

require('public/css/components/input-suggestions.scss');

const SEARCH_DEBOUNCE_DELAY = 400;
const SELECTED_CLASSNAME = 'selected-suggestion';
const WINDOW_RESIZE_DEBOUNCE = 1000 / 60; // "60fps" -> 16.6667ms
const MINIMUM_INPUT_CHARACTERS = 3;

const inputSuggestions = {};

// Manage suggestion box state. There might be multiple searches on a page, so we have to manage them individually :|
const suggestionContainers = {};
const selectedSuggestionIndices = {};

const debouncedOnInputValueChange = debounce(onInputValueChange, SEARCH_DEBOUNCE_DELAY);
const debounceOnWindowResize = debounce(updateSuggestionContainerDimensions, WINDOW_RESIZE_DEBOUNCE);

/**
 * Given an input element, create a container below it that will hold the suggestion elements.
 * @param inputEl
 */
inputSuggestions.initialize = function(inputEl) {
	const newSuggestionContainer = document.createElement('div');
	newSuggestionContainer.className = 'suggestion-container';
	suggestionContainers[inputEl.id] = newSuggestionContainer;
	selectedSuggestionIndices[inputEl.id] = -1;
	updateSuggestionsDisplay(inputEl, false);
	const targetElementSelector = window.__IS_WEBSTORE_PLUGIN ? '#rp_webstore' : 'body';
	const targetElement = document.querySelector(targetElementSelector);
	//Remove any instances already inserted into the dom (IE Friendly Way)
	const existingElements = document.querySelectorAll('.suggestion-container');
	for (let i = 0; i < existingElements.length; i++) {
		targetElement.removeChild(existingElements[i]);
	}
	targetElement.appendChild(newSuggestionContainer);

	// Add listeners
	inputEl.addEventListener('input', debouncedOnInputValueChange);
	inputEl.addEventListener('keydown', onInputKeyDown);
	function onClickOutside(e) {
		if ((e.target === inputEl) && newSuggestionContainer.hasChildNodes()) {
			updateSuggestionsDisplay(inputEl, true);
		} else if (!newSuggestionContainer.contains(e.target)) {
			updateSuggestionsDisplay(inputEl, false);
		}
	}
	document.addEventListener('click', onClickOutside);
	// Bind input to resize function to keep track of the input it's associated with
	window.addEventListener('resize', debounceOnWindowResize.bind(this, inputEl));
};

function updateSuggestionsDisplay(inputEl, shouldDisplay = true) {
	const suggestionContainer = suggestionContainers[inputEl.id];
	if (suggestionContainer) {
		suggestionContainer.style.display = shouldDisplay ? '' : 'none';
		updateSuggestionContainerDimensions(inputEl);
	}
}

function onInputValueChange(e) {
	const inputEl = e.target;
	const suggestionContainer = suggestionContainers[inputEl.id];
	const value = encodeURIComponent(trim(e.target.value));

	if (value && value.length >= MINIMUM_INPUT_CHARACTERS) {
		// Fetch new results for value
		window.rp_app.request({
			type: 'GET',
			url: `/ajax/suggestions/search?search=${value}`,
			dataType: 'json',
			contentType: 'application/json',
			success: function (data) {
				const html = data.html;
				suggestionContainer.innerHTML = html;
				updateSuggestionsDisplay(inputEl, data.count);
				selectedSuggestionIndices[inputEl.id] = -1;
			},
		});
	} else {
		suggestionContainer.textContent = '';
		updateSuggestionsDisplay(inputEl, false);
	}
}

function updateSelectedSuggestionIndex(inputEl, indexChange) {
	const currentIndex = selectedSuggestionIndices[inputEl.id];
	const suggestionContainer = suggestionContainers[inputEl.id];
	const suggestions = suggestionContainer.children;

	// Deselect existing selected suggestion
	const currentSelectedSuggestion = suggestions.item(currentIndex);
	if (currentSelectedSuggestion) {
		currentSelectedSuggestion.classList.remove(SELECTED_CLASSNAME);
	}

	const newIndex = currentIndex + indexChange;
	const indexTooHigh = ((newIndex + 1) > suggestions.length);
	const indexTooLow = newIndex < -1;
	if (indexTooHigh) {
		selectedSuggestionIndices[inputEl.id] = -1;
	} else if (indexTooLow) {
		selectedSuggestionIndices[inputEl.id] = suggestions.length - 1;
	} else {
		selectedSuggestionIndices[inputEl.id] = newIndex;
	}

	// Select new suggestion at index
	if (selectedSuggestionIndices[inputEl.id] !== -1) {
		const newSelectedSuggestion = suggestions.item(selectedSuggestionIndices[inputEl.id]);
		newSelectedSuggestion.classList.add(SELECTED_CLASSNAME);
	}
}

function onInputKeyDown(e) {
	const keyCode = e.keyCode;
	const suggestionContainer = suggestionContainers[e.target.id];
	const hasSuggestions = suggestionContainer.hasChildNodes();

	if (hasSuggestions) {
		if (keyCode === 13) {
			// Enter
			const suggestions = suggestionContainer.children;
			const currentIndex = selectedSuggestionIndices[e.target.id];
			if (currentIndex !== -1) {
				const currentSuggestion = suggestions.item(currentIndex);
				window.location.href = currentSuggestion.href;
				e.preventDefault();
			}
		} else if (keyCode === 38) {
			// R2, we need to be going UP
			updateSelectedSuggestionIndex(e.target, -1);
			e.preventDefault();
		} else if (keyCode === 40) {
			// Down
			updateSelectedSuggestionIndex(e.target, 1);
			e.preventDefault();
		} else if (keyCode === 27) {
			// Esc
			updateSuggestionsDisplay(e.target, false);
		}
	}
}

function updateSuggestionContainerDimensions(inputEl) {
	const suggestionContainer = suggestionContainers[inputEl.id];
	if (suggestionContainer.style.display !== 'none') {
		debug('suggestionContainer.getBoundingClientRect()', suggestionContainer && suggestionContainer.getBoundingClientRect());
		const inputBounding = inputEl.getBoundingClientRect();
		debug('inputEl.getBoundingClientRect()', inputBounding);
		let top = inputBounding.bottom + window.pageYOffset;
		let left = inputBounding.left + window.pageXOffset;

		if (window.__IS_WEBSTORE_PLUGIN) {
			const rpPluginContainer = document.querySelector('#rp_webstore');
			if (rpPluginContainer) {
				const pluginBounding = rpPluginContainer.getBoundingClientRect();
				debug('pluginContainer.getBoundingClientRect()', pluginBounding);
				top = inputBounding.bottom - pluginBounding.top;
				left = inputBounding.left - pluginBounding.left;
			}
		}

		suggestionContainer.style.top = `${top}px`;
		suggestionContainer.style.left = `${left}px`;
		suggestionContainer.style.width = `${inputBounding.width}px`;
		positioningDebug(top, left, inputBounding.width);
	}
}

function positioningDebug(top, left, width) {
	debug('suggestion-container final positioning', {
		top,
		left,
		width,
		'window.pageYOffset': window.pageYOffset,
		'window.pageXOffset': window.pageXOffset,
	});
}

module.exports = inputSuggestions;
